import { useAppSelector } from '@hospy/store';
import { Layout, Spin } from 'antd';
import IndeterminateLoading from 'libs/hospy-ui/src/lib/atoms/indeterminate-loading';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import DashboardRoutes from '../routes/dashboard-routes';
import NavBar from './nav-bar';
import { useLocation } from 'react-router-dom';

const DashboardLayout = () => {
	const theme: any = useTheme();
	const { loading } = useAppSelector(({ user }) => user);

	const [scrollPosition, setScrollPosition] = useState(0);
	const handleScroll = () => {
		setScrollPosition(window.scrollY);
	};

	const location = useLocation();
	const [themeVariant, setThemeVariant] = useState<boolean>(false);

	const themeRoutes: any = [];

	useEffect(() => {
		const changeTheme = themeRoutes.includes(location.pathname);
		setThemeVariant(changeTheme);
	}, [location.pathname]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<NavBar
				style={{
					container: {
						borderBottom: 0,
						backgroundColor:
							scrollPosition > (theme.screens.xs ? 40 : 80)
								? themeVariant
									? '#04133bcc'
									: '#ffffffcc'
								: 'transparent',
						backdropFilter:
							scrollPosition > (theme.screens.xs ? 40 : 80)
								? 'blur(8px)'
								: 'blur(0px)',
						boxShadow:
							scrollPosition > (theme.screens.xs ? 40 : 80)
								? themeVariant
									? '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
									: '0 3px 6px rgba(145, 145, 145, 0.16), 0 3px 6px rgba(182, 182, 182, 0.23)'
								: '0 3px 6px rgba(255, 255, 255, 0), 0 3px 6px rgba(255, 255, 255, 0)',
						transition: 'all .2s',
						zIndex: 5
					},
					nav: {
						maxWidth: 'calc(1280px + 4rem)',
						margin: '0 auto',
						backgroundColor: 'transparent'
					}
				}}
			/>
			<Main>
				<React.Suspense fallback={<IndeterminateLoading />}>
					<DashboardRoutes />
				</React.Suspense>
			</Main>
			{loading === 'pending' && <Loading />}
		</Layout>
	);
};

export default DashboardLayout;

const Main = styled(Layout)`
	width: 100%;
`;

const Loading = () => (
	<div
		style={{
			width: '100%',
			minHeight: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'absolute',
			zIndex: 60,
			top: 0,
			left: 0,
			backdropFilter: 'blur(3px)',
			background: '#ffffff54'
		}}
	>
		<Spin />
	</div>
);
