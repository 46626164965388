import { useState } from 'react';
import { Dropdown } from 'antd';
import { CircleFlag } from 'react-circle-flags';
import { Icon } from '@hospy/hospy-ui';
import { useTheme } from 'styled-components';
import {
	availableLanguages,
	setLanguage,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';

const LanguageSelect = ({
	showLanguageLabel = 'code',

	themeVariant
}: {
	showLanguageLabel?: 'code' | 'name';
	themeVariant?: boolean;
}) => {
	const theme: any = useTheme();
	const { language } = useAppSelector(({ language }) => language);

	const dispatch = useAppDispatch();

	const handleMenuClick = ({ key }: { key: string }) => {
		dispatch(setLanguage(key));
	};

	const languageOptions = [
		{
			label: (
				<span
					style={{
						display: 'flex',
						padding: '10px',
						alignItems: 'center',
						fontSize: theme.text.size.middle
					}}
				>
					<CircleFlag countryCode="es" width={20} height={20} />{' '}
					<span style={{ marginLeft: 10, flex: '1 auto' }}>
						{
							availableLanguages?.find((x) => x?.code === 'es')
								?.name[language]
						}
					</span>
				</span>
			),
			key: 'es'
		},
		{
			label: (
				<span
					style={{
						display: 'flex',
						padding: '10px',
						alignItems: 'center',
						fontSize: theme.text.size.middle
					}}
				>
					<CircleFlag countryCode="gb" width={20} height={20} />{' '}
					<span style={{ marginLeft: 10, flex: '1 auto' }}>
						{
							availableLanguages?.find((x) => x?.code === 'en')
								?.name[language]
						}
					</span>
				</span>
			),
			key: 'en'
		},
		{
			label: (
				<span
					style={{
						display: 'flex',
						padding: '10px',
						alignItems: 'center',
						fontSize: theme.text.size.middle
					}}
				>
					<CircleFlag countryCode="br" width={20} height={20} />{' '}
					<span style={{ marginLeft: 10, flex: '1 auto' }}>
						{
							availableLanguages?.find((x) => x?.code === 'pt')
								?.name[language]
						}
					</span>
				</span>
			),
			key: 'pt'
		}
	];

	return (
		<Dropdown
			trigger={['click']}
			placement="bottomCenter"
			menu={{
				selectable: true,
				selectedKeys: [language],
				onClick: handleMenuClick,
				items: languageOptions
			}}
			overlayStyle={{
				minWidth: '150px',
				maxWidth: '300px'
			}}
		>
			<div
				style={{
					cursor: 'pointer',
					display: 'flex',
					justifyContent: 'center',
					gap: '10px',
					alignItems: 'center',
					color:
						themeVariant && !showLanguageLabel
							? 'white'
							: theme.colors.black.base
				}}
			>
				{showLanguageLabel === 'code' && (
					<span
						style={{
							margin: '3px 0 0 3px',
							display: 'flex',
							alignItems: 'center',
							height: '25px'
						}}
					>
						{language.toUpperCase()}
					</span>
				)}
				{showLanguageLabel === 'name' && (
					<span
						style={{
							margin: '3px 0 0 3px',
							display: 'flex',
							alignItems: 'center',
							height: '25px'
						}}
					>
						{
							availableLanguages?.find(
								(x) => x?.code === language
							)?.name[language]
						}
					</span>
				)}

				<CircleFlag
					countryCode={
						language === 'es'
							? 'es'
							: language === 'en'
							? 'gb'
							: 'br'
					}
					width={25}
					height={25}
				/>
			</div>
		</Dropdown>
	);
};

export default LanguageSelect;
