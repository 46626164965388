import { accountRoute, lazyWithRetry } from '@hospy/shared-components';
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard-layout';
const MyFiles = lazyWithRetry(() => import('@spa-pages/files'));
const GetStarted = lazyWithRetry(
	() => import('@spa-pages/get-started/get-started')
);

const SiigoPartnerLanding = lazyWithRetry(
	() => import('@spa-pages/siigo-partner-landing')
);

const GetStartedComplete = lazyWithRetry(
	() => import('@spa-pages/get-started/components/steps/step-5')
);

const WhatsApp = lazyWithRetry(() => import('@spa-pages/whatsapp'));

const PathNotFound = lazyWithRetry(
	() => import('@spa-pages/path-not-found')
)

const RedirectUrl = ({ url }: { url: string }) => {
	useEffect(() => {
		window.location.href = url;
	}, [url]);
	return <> </>;
};

const AppRoutes = () => {
	const routes = useRoutes([
		...accountRoute.map(({ path, component: Cmp }) => ({
			key: path,
			path,
			element: <Cmp />
		})),
		{
			path: 'get-started',
			element: <GetStarted />
		},
		{
			path: 'files',
			element: <MyFiles />
		},
		{
			path: 'tra',
			element: (
				<RedirectUrl url="https://help.hospy.co/es/articles/8152840-que-son-los-reportes-de-tra-sire-para-alojamientos-en-colombia" />
			)
		},
		{
			path: 'airbnb-chat-group',
			element: (
				<RedirectUrl url="https://chat.whatsapp.com/HfIr0JDpYoPHqNnPCkZzHQ" />
			)
		},
		{
			path: 'go-whatsapp',
			element: (
				<RedirectUrl url="https://api.whatsapp.com/send?phone=13214482334&text=Hola,%20soy%20host%20de%20airbnb%20y%20me%20interesa%20hospy" />
			)
		},
		{
			path: 'siigo-partner-landing',
			element: <SiigoPartnerLanding />
		},
		{
			path:'whatsapp',
			element: <WhatsApp />
		},
		{
			path:'not-found',
			element: <PathNotFound />
		},
		{
			path: '*',
			element: <DashboardLayout />
		}
	]);
	return routes;
};
export default AppRoutes;
