import { internalRoutes, lazyWithRetry } from '@hospy/shared-components';
import { useRoutes } from 'react-router-dom';

const Landing = lazyWithRetry(() => import('@spa-pages/landing'));
const LandingHome = lazyWithRetry(() => import('@spa-pages/landing/home'));
// const LandingPrices = lazyWithRetry(() => import('@spa-pages/landing/prices'));
const Videos = lazyWithRetry(() => import('@spa-pages/videos/components/videos-list'));
const VideoDetails = lazyWithRetry(() => import('@spa-pages/videos/components/video-details'));

const DashboardRoutes = () => {
	const routes = useRoutes([
		...internalRoutes.map(({ path, component: Cmp }) => ({
			key: path,
			path,
			element: <Cmp />
		})),
		{
			path: 'videos',
			element: <Videos />
		},
		{
			path: 'videos/:slug',
			element: <VideoDetails />
		},
		{
			path: '',
			element: <Landing />,
			children: [
				{ path: '', element: <LandingHome /> }
				// { path: '/prices', element: <LandingPrices /> }
			]
		}
	]);
	return routes;
};
export default DashboardRoutes;
