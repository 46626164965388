import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    min-height: 100vh;
    font-family: InterRegular;
}

.message-mw-300 .ant-message-notice-content {
	max-width: 300px;
    text-align: left;
}
.message-mw-600 .ant-message-notice-content {
	max-width: 600px;
    text-align: left;
}
.message-mw-800 .ant-message-notice-content {
	max-width: 800px;
    text-align: left;
}

.react-tel-input.disabled input[disabled] {
    color: rgba(0,0,0,.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.react-tel-input.disabled .flag-dropdown {
    opacity: 0.5;
}

.ant-form-item-with-help {
    margin-bottom: 24px;
}

.react-tel-input input[disabled] {
    color: rgba(0,0,0,.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.react-tel-input input[disabled] + .flag-dropdown  {
    opacity: 0.3;
}

b {
    font-family: InterRegularBold;
}
strong{
    font-family: InterRegularBold;
}
`;

export default GlobalStyles;
